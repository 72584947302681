<template>
  <li v-if="groupTitle" class="nav-item mt-3">
    <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
      {{ groupTitle }}
    </h6>
  </li>
  <router-link
    :to="{ path: menulink }"
    class="nav-link"
    :class="{ 'ml-8': isSubMenu }"
    @click="toggle"
  >
    <div
      class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"
    >
      <i :class="menuicon" aria-hidden="true"></i>
    </div>
    <span class="nav-link-text ms-1">{{ menuname }}</span>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  emits: ["toggle"],
  props: {
    menuicon: String,
    menuname: String,
    menulink: String,
    groupTitle: String,
    isSubMenu: Boolean,
  },
  methods: {
    toggle() {
      this.$emit("toggle");
    },
  },
  data: () => ({}),
};
</script>

<style scoped></style>
